const nextSeoConfig = {
  title: 'AirShaper - Aerodynamics Made Easy',
  description:
    'Online aerodynamics tool for engineers and designers. Test and improve on aerodynamic efficiency of 3D models. Analyse drag, lift, downforce and noise.',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://airshaper.com',
    site_name: 'AirShaper',
    title: 'AirShaper - Aerodynamics Made Easy',
    images: [{ url: 'https://airshaper.com/assets/images/card.jpg' }],
    description:
      'Online aerodynamics tool for engineers and designers. Test and improve on aerodynamic efficiency of 3D models. Analyse drag, lift, downforce and noise.',
  },
  twitter: {
    title: 'AirShaper - Aerodynamics Made Easy',
    description:
      'Online aerodynamics tool for engineers and designers. Test and improve on aerodynamic efficiency of 3D models. Analyse drag, lift, downforce and noise.',
    handle: '@Air_Shaper',
    cardType: 'summary_large_image',
    image: 'https://airshaper.com/assets/images/card.jpg',
    site: 'https://airshaper.com',
  },
}

export default nextSeoConfig
